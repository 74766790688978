<template>
    <div class="page">
        <SecondFloor width="100%" />

        <div class="grey--text">
            Tap on the stations (<v-icon small>mdi-map-marker</v-icon>) for more details
        </div>

        <v-row no-gutters>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/first-floor">
                    FIRST FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/second-floor">
                    SECOND FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="4" class="px-1">
                <v-btn block depressed rounded dark color="grey darken-4" style="font-size: 9px;" to="/third-floor">
                    THIRD FLOOR 
                    <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="patternMakingDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Pattern <br>Making Room</v-card-title>
                    Take a peek at our pattern making room where our designers create and make new patterns, test fabrics, and create the collections your see walking the runway. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="photoStudioDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Photo Studio</v-card-title>
                    Step into our Photo Studio where the e-commerce photography and various shoots take place.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="womanDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">LFA Women</v-card-title>
                    Shop or women’s collection or design your dream suit with our custom-made program. 
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="designerDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Designer's Studio</v-card-title>
                    <div class="subtitle">Create your own style</div>
                    Experience the collection museum where we take you behind the scenes on the NYFW 2023 backstage at Spring Studios.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="creativeDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Creative Studio</v-card-title>
                    Experience first-hand where the creative thinking happens! From the podcast lounge to the creative office, and even walk through some of our past collections.
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="legacyDialog">
            <v-card>
                <v-img src=""></v-img>
                <v-card-text>
                    <v-card-title class="px-0">Legacy & Future</v-card-title>
                    Relieve our gala-concert with the Puerto Rico symphony orchestra in a cozy cinematic experience.
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SecondFloor from '../assets/second-floor.svg?inline';
export default {
    data: () => ({
        patternMakingDialog : false,
        photoStudioDialog   : false,
        womanDialog         : false,
        designerDialog      : false,
        creativeDialog      : false,
        legacyDialog        : false,
    }),
    mounted() {
        document.title = 'LFA | HOF - Second Floor';
        setTimeout(() => {
            this.init();
        }, 500 );
    },
    methods: {
        init() {
            document.getElementById('patterMaking').addEventListener('click', () => {
                this.patternMakingDialog = true;
            });
            document.getElementById('photoStudio').addEventListener('click', () => {
                this.photoStudioDialog = true;
            });
            document.getElementById('designerStudio').addEventListener('click', () => {
                this.designerDialog = true;
            });
            document.getElementById('creativeStudio').addEventListener('click', () => {
                this.creativeDialog = true;
            });
            document.getElementById('legacyFuture').addEventListener('click', () => {
                this.legacyDialog = true;
            });
        }
    },
    components: { SecondFloor }
}
</script>

<style lang="scss" scoped>
.page {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
svg text {
    font-size: 6px;
}
</style>